import React, {useState} from 'react'
import {FaBars, FaTimes, FaInstagram, FaLinkedin, FaYoutube} from 'react-icons/fa'
import Logo from '../assets/new-logo.png'
import {Link} from 'react-scroll'

const Navbar = () => {
	const [nav, setNav] = useState(false)
	const handleClick = () => setNav(!nav)

	return (
	<div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#000] text-gray-300 z-40'>
			<div>
				<a href='/'>
					<img src={Logo} alt='Logo' style={{width: '50px'}} />
				</a>
			</div>

			{/* menu */}
			<ul className='hidden md:flex'>
				<li>
					<Link activeClass="active" to="home" smooth={true} duration={500}>Home</Link>
				</li>
				<li><Link activeClass="active" to="about" smooth={true} duration={500}>About</Link></li>
				<li><Link activeClass="active" to="skills" smooth={true} duration={500}>Skills</Link></li>
				<li><Link activeClass="active" to="work" smooth={true} duration={500}>Work</Link></li>
				<li><Link activeClass="active" to="contact-form" smooth={true} duration={500}>Contact</Link></li>
			</ul>

			{/* hamburger */}
			<div onClick={handleClick} className='md:hidden z-10'>
				{!nav ? <FaBars /> : <FaTimes />}
			</div>

			{/* Mobile menu */}
			<ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
					<li className='py-6 text-4xl'><Link onClick={handleClick} activeClass="active" to="home" smooth={true} duration={500}>Home</Link></li>
					<li className='py-6 text-4xl'><Link onClick={handleClick} activeClass="active" to="about" smooth={true} duration={500}>About</Link></li>
					<li className='py-6 text-4xl'><Link onClick={handleClick} activeClass="active" to="work" smooth={true} duration={500}>Skills</Link></li>
					<li className='py-6 text-4xl'><Link onClick={handleClick} activeClass="active" to="work" smooth={true} duration={500}>Work</Link></li>
					<li className='py-6 text-4xl'><Link onClick={handleClick} activeClass="active" to="contact-form" smooth={true} duration={500}>Contact</Link></li>
			</ul>

			{/* Social icons */}
			<div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
				<ul>
				<li className='w-[60px] h-[60px] flex justify-between items-center duration-300 bg-blue-600'>
							<a className='flex justify-between items-center w-full text-gray-300' rel='noreferrer' target="_blank" href='https://www.linkedin.com/in/vadym-valihun/'>
								<FaLinkedin size={30} />
							</a>
						</li>
						<li className='w-[60px] h-[60px] flex justify-between items-center duration-300 bg-[#c4302b]'>
							<a className='flex justify-between items-center w-full text-gray-300' rel='noreferrer' target="_blank" href='https://www.youtube.com/@wvadym'>
								<FaYoutube size={30} />
							</a>
						</li>
						<li className='w-[60px] h-[60px] flex justify-between items-center bg-gradient-to-r from-[#ee2a7b] to-[#6228d7]'>
							<a className='flex justify-between items-center w-full text-gray-300' rel='noreferrer' target="_blank" href='https://www.instagram.com/w.vadym/'>
								<FaInstagram size={30} />
							</a>
						</li>
				</ul>

			</div>
		</div>
	)
}

export default Navbar