import Navbar from "./components/Navbar";
import Home from "./components/Home"
import About from "./components/About";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import React, {useEffect} from 'react'


function App() {

	// color for background = bg-[#0a192f]
	useEffect(() => {
		document.title = "Vadym Valihun - Cinematographer";
	}, []);

  return (
    <div>
      <Navbar />
			<Home />
			<About />
			<Skills />
			<Work />
			<Contact />
			<Footer />
    </div>
  );
}

export default App;
