import React from 'react'
import Photoshop from '../assets/photoshop-240.png'
import Resolve from '../assets/davinci-resolve-400.png'
import Blender from '../assets/blender-240.png'
import Premiere from '../assets/adobe-premiere-pro-240.png'
import Lightroom from '../assets/adobe-lightroom-240.png'
import Illustrator from '../assets/adobe-illustrator-240.png'
import AfterEffects from '../assets/adobe-after-effects-240.png'
import Ableton from '../assets/ableton-live-256.png'

const Skills = () => {
	return (
		<div name='skills' className='w-full h-screen bg-[#eee] text-000 border-t-8 border-black'>
			{/* Container */}
			<div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
				<div>
					<p className='text-4xl font-bold inline border-b-4 border-yellow-300'>Skills</p>
					<p className='py-4'>// These are the programs that I've worked with</p>
				</div>

				<div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={Resolve} className='w-20 mx-auto' alt='DaVince Resolve icon' />
						<p className='my-4'>DaVinci Resolve</p>
					</div>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={Premiere} className='w-20 mx-auto' alt='Premiere Pro icon' />
						<p className='my-4'>Premiere Pro</p>
					</div>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={AfterEffects} className='w-20 mx-auto' alt='Affter Effects icon' />
						<p className='my-4'>Affter Effects</p>
					</div>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={Photoshop} className='w-20 mx-auto' alt='Photoshop icon' />
						<p className='my-4'>Photoshop</p>
					</div>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={Lightroom} className='w-20 mx-auto' alt='Lightroom icon' />
						<p className='my-4'>Lightroom</p>
					</div>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={Illustrator} className='w-20 mx-auto' alt='Illustrator icon' />
						<p className='my-4'>Illustrator</p>
					</div>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={Ableton} className='w-20 mx-auto' alt='Ableton icon' />
						<p className='my-4'>Ableton Live</p>
					</div>
					<div className='shadow-[10px_10px_15px_0px] shadow-[#aaa] hover:scale-110 duration-500 rounded-xl'>
						<img src={Blender} className='w-20 mx-auto' alt='Blender icon' />
						<p className='my-4'>Blender</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Skills