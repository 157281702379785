import React from 'react'
import Colorgrade from '../assets/projects/Colorgrading.png'
import MusicVideo from '../assets/projects/music-video.png'
import ShortMovieLife from '../assets/projects/sm-life.png'
import Rain from '../assets/projects/mv-rain.jpg'
import Painting from '../assets/projects/painting.png'
import SeeYouAgain from '../assets/projects/see-you-again.png'

const Work = () => {
	return (
		<div name='work' className='w-full md:h-screen text-gray-300 bg-[#000]'>
			{/* Container */}
			<div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
				<div className='pb-8 pt-16'>
					<p className='text-4xl font-bold inline border-b-4 text-gray-300 border-yellow-300'>Work</p>
					<p className='py-6'>// Check out some of my recent work</p>
				</div>

				<div className='grid sm:gird-cols-2 md:grid-cols-3 gap-4'>
					<div style={{backgroundImage: `url(${MusicVideo})`}} className='shadow-[2px_4px_5px_0px] shadow-yellow-300 hover:shadow-[1px_1px_40px_0px] hover:scale-105 transition duration-100 ease-out group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center transition duration-400 ease-in-out'>
							<span className='text-2xl font-bold text-white'>Music Video | Gasoline</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=mSGUOg1siz0' target='_blank'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${Colorgrade})`}} className='shadow-[2px_4px_5px_0px] shadow-yellow-300 hover:shadow-[1px_1px_40px_0px] hover:scale-105 transition duration-100 ease-out group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center'>
							<span className='text-2xl font-bold text-white'>Colorgrade | Euphoria</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=rLR4cUhgBlU' target='_blank'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${ShortMovieLife})`}} className='shadow-[2px_4px_5px_0px] shadow-yellow-300 hover:shadow-[1px_1px_40px_0px] hover:scale-105 transition duration-100 ease-out group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center transition duration-400 ease-in-out'>
							<span className='text-2xl font-bold text-white'>Short Movie | Life</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=xEMBlMVUjqg' target='_blank'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${Rain})`}} className='shadow-[2px_4px_5px_0px] shadow-yellow-300 hover:shadow-[1px_1px_40px_0px] hover:scale-105 transition duration-100 ease-out group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center transition duration-400 ease-in-out'>
							<span className='text-2xl font-bold text-white'>Music Video | Rain</span>
							<div className='pt-8 text-center'>
								<a href='https://www.youtube.com/watch?v=ZVJ3VkTP-Mo' target='_blank'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${Painting})`}} className='shadow-[2px_4px_5px_0px] shadow-yellow-300 hover:shadow-[1px_1px_40px_0px] hover:scale-105 transition duration-100 ease-out group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center transition duration-400 ease-in-out'>
							<span className='text-2xl font-bold text-white'>Pain-ting | Short Film</span>
							<div className='pt-8 text-center'>
								<a href='https://youtu.be/lmQ4vfULnOA' target='_blank'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
					<div style={{backgroundImage: `url(${SeeYouAgain})`}} className='shadow-[2px_4px_5px_0px] shadow-yellow-300 hover:shadow-[1px_1px_40px_0px] hover:scale-105 transition duration-100 ease-out group container rounded-md flex justify-center items-center mx-auto content-div'>
						{/* HOVER EFFECTS */}
						<div className='opacity-0 group-hover:opacity-100 text-center transition duration-400 ease-in-out'>
							<span className='text-2xl font-bold text-white'>See you Again | Music Video</span>
							<div className='pt-8 text-center'>
								<a href='https://youtu.be/eCXOrYMN-Fc' target='_blank'>
									<button className='text-center rounder-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Work