import React from 'react'

const About = () => {
	return (
		<div name='about' className='w-full h-screen bg-[#eee] text-000'>
			<div className='flex flex-col justify-center items-center w-full h-full'>
				<div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
					<div className='sm:text-right pb-8 pl-4'>
						<p className='text-4xl font-bold inline border-b-4 border-yellow-300'>About Me</p>
					</div>
					<div></div>
				</div>
				<div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
					<div className='sm:text-right text-4xl font-bold'>
						<p>A DP comes from overseas</p>
					</div>
					<div>
						<p>I’m passionate about bringing stories to life through the lens and the art of post-production. With a focus on cinematography and visual storytelling, I specialize in creating captivating visuals that not only look stunning but also resonate on a deeper level.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About