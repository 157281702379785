import React, {useState} from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll'
import Photo from '../assets/my-photo.png'

const Home = () => {
	const [nav, setNav] = useState(false)
	const handleClick = () => setNav(!nav)
	return (
		<div name='home' className='w-full h-screen bg-[#000] flex items-center'>

			{/* Container */}
			<div className='max-w-[1000px] mx-auto grid grid-cols-3 gap-8'>
				<div className='px-8 flex flex-col justify-center h-full col-span-full md:col-span-2 lg:ml-12'>
					<p className='text-yellow-300'>I'm Vadym Valihun</p>
					<h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>A Filmmaker</h1>
					<h2 className='text-sm sm:text-7xl font-bold text-[#8892b0]'>with a passion for creating cinematic experiences</h2>
					<p className='text-[#8892b0] py-4 max-w-[700px]'>I bring a unique visual perspective that not only elevates your brand’s social presence but also enhances its value. Whether it’s storytelling through the camera or crafting visuals that leave a lasting impact, I’m here to take your vision to the next level.</p>
					<div>
					<Link onClick={handleClick} activeClass="active" to="work" smooth={true} duration={500}>
						<button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-blue-600 hover:border-blue-600'>View work
								<span className='group-hover:rotate-90 duration-300'>
									<HiArrowNarrowRight className='ml-3' />
								</span>
							</button>
					</Link>
					</div>
				</div>
				<div className='hidden md:block'>
					<img src={Photo} alt='Vadym Valihun' />
				</div>
			</div>
		</div>
	)
}

export default Home